import React, { useCallback, useContext, useEffect, useState } from "react";
import { SessionContext } from "../App";
import { Checkbox } from "../components/ui/checkbox";
import { Input } from "../components/ui/input";
import { fetchDividendScreening } from "../data/api";
import DashboardLayout from "../layout/dashboard";
import { cn } from "../lib/utils";

export default function DividendPage() {
  const session = useContext(SessionContext);
  const [response, setResponse] = useState<DividendItems | null>();
  const [filterHasHolder, setFilterHasHolder] = useState<boolean>(false);
  const [filterDate, setFilterDate] = useState("");

  const fetch = useCallback(async () => {
    const response = await fetchDividendScreening(filterHasHolder, filterDate);
    if (response.status < 200 || response.status > 299) {
      return;
    }
    const data = JSON.parse(await response.text()) as DividendItems;
    setResponse(data);
  }, [filterHasHolder, filterDate]);

  useEffect(() => {
    (async () => await fetch())();
  }, [fetch]);

  // Guard no-authenticated
  if (!session?.username) return <></>;

  return (
      <DashboardLayout>
        <main className={cn("w-full")}>
          <header className={cn("pb-6 flex justify-end w-full")}>
            <div className="flex items-center space-x-2">
              <Input
                  type="text"
                  placeholder="Date"
                  value={filterDate}
                  required
                  onChange={(e) => setFilterDate(e.target.value)}
              />
            </div>
            <div style={{width: 24}}/>
            <div className="flex items-center space-x-2">
              <Checkbox
                  id="filter-holder"
                  checked={filterHasHolder}
                  onClick={() => setFilterHasHolder(!filterHasHolder)}
              />
              <label
                  htmlFor="filter-holder"
                  className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                Only has holders
              </label>
            </div>
          </header>
          <ul>
            {response?.items.map((item: DividendItem) => {
              return (
                  <li
                      key={item.stock.code}
                      className={cn(
                          "flex flex-col border-2 rounded mb-2 px-4 py-2 gap-4 lg:gap-2",
                      )}
                      onMouseEnter={() => {
                      }}
                      onMouseLeave={() => {
                      }}
                  >
                    <div className={cn("flex gap-4 items-center")}>
                      <div className={cn("font-bold")}>{item.stock.code}</div>
                      <div className={cn("text-xs text-muted-foreground")}>
                        {item.stock.name}
                      </div>
                    </div>
                    <div
                        className={cn(
                            "flex flex-col gap-1 items-top lg:items-center lg:flex-row",
                        )}
                    >
                      <div className={cn("text-sm w-16")}>
                        ¥{item.stock.price?.toLocaleString()}
                      </div>
                      <div
                          className={cn("flex flex-col gap-2 items-top lg:flex-row")}
                      >
                        <div className={cn("flex gap-2 items-top")}>
                          <div className={cn("text-sm lg:w-16 lg:text-end")}>
                            ¥{item.stock.dividend_price?.toLocaleString()}
                          </div>
                          <div className={cn("text-sm lg:w-16 lg:text-end")}>
                            ({item.stock.dividend_rate}%)
                          </div>
                        </div>
                        <div className={cn("flex gap-4 items-top")}>
                          <div className={cn("text-xs lg:w-40 lg:pl-8")}>
                            {item.stock.dividend_date_1}{" "}
                            {item.stock.dividend_date_2}
                          </div>
                          <div className={cn("text-xs w-24")}>
                            {item.stock.dividend_count_in_year} times in year
                          </div>
                        </div>
                      </div>
                      <div className={cn("flex flex-1 gap-2 lg:pl-8")}>
                        {item.labels?.map((label, index) => {
                          if (item.labels && item.labels.length - 1 !== index) {
                            return (
                                <span className={cn("text-xs")} key={label}>
                            {label}
                          </span>
                            );
                          }

                          return (
                              <span className={cn("text-xs")} key={label}>
                          {label}
                        </span>
                          );
                        })}
                      </div>
                      <div className={cn("flex justify-end")}>
                        <a
                            href={`https://www.buffett-code.com/company/${item.stock.code}/`}
                        >
                          BC
                        </a>
                      </div>
                    </div>
                  </li>
              );
            })}
          </ul>
        </main>
      </DashboardLayout>
  );
}
