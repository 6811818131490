import {
    CalendarIcon,
  HomeIcon,
  MenuIcon,
  NewspaperIcon,
  TableIcon,
  WalletIcon,
  XIcon,
} from "lucide-react";
import React, { type ReactNode, useContext, useState } from "react";
import { SessionContext } from "../App";
import { Sidebar } from "../components/sidebar";
import { Button, buttonVariants } from "../components/ui/button";
import { logout } from "../data/api";
import { cn } from "../lib/utils";

type Props = { children: ReactNode };
export default function DashboardLayout({ children }: Props) {
  const session = useContext(SessionContext);
  const [drawerShown, setDrawerShown] = useState(false);
  const onClickedLogout = async () => {
    await logout();
    session?.setUsername(null);
  };

  const nav = () => {
    return (
      <Sidebar
        items={[
          {
            href: "/",
            title: "Home",
            icon: <HomeIcon strokeWidth={1.4} size={20} />,
          },
          {
            href: "/dividend",
            title: "Dividend",
            icon: <TableIcon strokeWidth={1.4} size={20} />,
          },
          {
            href: "/portfolio",
            title: "Portfolio",
            icon: <WalletIcon strokeWidth={1.4} size={20} />,
          },
          {
            href: "/news",
            title: "IR Notifier",
            icon: <NewspaperIcon strokeWidth={1.4} size={20} />,
          },
            {
                href: "/schedule",
                title: "Schedule",
                icon: <CalendarIcon strokeWidth={1.4} size={20}/>,
            },
        ]}
      />
    );
  };

  return (
    <div className="flex flex-col sm:flex-row min-h-dvh">
      {/* For PC */}
      <aside className={cn("hidden sm:flex flex-col w-64 p-4")}>
        {nav()}
        <div className={cn("min-w-max bg-border h-[1px] my-4")} />
        <Button
          variant={"ghost"}
          onClick={onClickedLogout}
          className={cn(
            buttonVariants({ variant: "ghost" }),
            "hover:bg-transparent hover:underline",
          )}
        >
          Logout
        </Button>
      </aside>

      {/* For SP */}
      <header
        className={cn("flex sm:hidden w-full p-4 sticky top-0 bg-background")}
      >
        <Button
          variant={"ghost"}
          className={cn("text-2xl")}
          onClick={() => setDrawerShown(true)}
        >
          <MenuIcon />
        </Button>
      </header>
      <div
        className={cn(
          "flex flex-col w-full min-h-dvh sm:hidden fixed z-40 bg-background",
          drawerShown ? "" : "hidden",
        )}
      >
        <header className={cn("flex sm:hidden w-full p-6")}>
          <Button
            variant={"ghost"}
            className={cn("text-4xl")}
            onClick={() => setDrawerShown(false)}
          >
            <XIcon />
          </Button>
        </header>
        <div className={cn("p-6")}>
          {nav()}
          <div className={cn("min-w-max bg-border h-[1px] my-8")} />
          <Button
            variant={"ghost"}
            onClick={onClickedLogout}
            className={cn(
              buttonVariants({ variant: "ghost" }),
              "hover:bg-transparent hover:underline",
            )}
          >
            Logout
          </Button>
        </div>
      </div>

      {/* For PC */}
      <div className={cn("hidden sm:flex bg-border min-h-dvh w-[1px]")} />

      {/* Common */}
      <main className="flex flex-col flex-1 p-4 overflow-x-auto">
        <div className="flex items-center justify-between border-gray-200">
          {children}
        </div>
      </main>
    </div>
  );
}
