import React, { useCallback, useContext, useEffect, useState } from "react";
import { SessionContext } from "../App";
import { Button } from "../components/ui/button";
import {
  fetchOperations,
  postMasterUpdaterOperation,
  postRefreshFinancialAnnouncementOperation,
} from "../data/api";
import DashboardLayout from "../layout/dashboard";
import { cn } from "../lib/utils";

export default function HomePage() {
  const session = useContext(SessionContext);
  const [jobs, setJobs] = useState<Job>();

  // MasterUpdate
  const [isExecutingMasterUpdate, setIsExecutingMasterUpdate] = useState(false);
  const execMasterUpdater = async () => {
    setIsExecutingMasterUpdate(true);
    const response = await postMasterUpdaterOperation();
    if (response.status < 200 || response.status > 299) {
      return;
    }
    setIsExecutingMasterUpdate(false);

    await fetch();
  };

  // RefreshFinancialAnnouncement
  const [
    isExecutingRefreshFinancialAnnouncement,
    setIsRefreshFinancialAnnouncement,
  ] = useState(false);
  const execRefreshFinancialAnnouncement = async () => {
    setIsRefreshFinancialAnnouncement(true);
    const response = await postRefreshFinancialAnnouncementOperation();
    if (response.status < 200 || response.status > 299) {
      return;
    }
    setIsRefreshFinancialAnnouncement(false);

    await fetch();
  };

  const fetch = useCallback(async () => {
    const response = await fetchOperations();
    if (response.status < 200 || response.status > 299) {
      return;
    }
    const data = JSON.parse(await response.text()) as Job;
    setJobs(data);
  }, []);

  useEffect(() => {
    (async () => await fetch())();
  }, [fetch]);

  // Guard no-authenticated
  if (!session?.username) return <></>;

  return (
    <DashboardLayout>
      <main className={cn("flex flex-col w-full items-stretch")}>
        <div className={cn("w-max")}>
          <span>Hello, {session?.username}!</span>
        </div>
        <div className={cn("bg-border min-w-screen h-[1px] mt-4 mb-8")} />

        <h2 className={cn("text-4xl mb-6")}>Operations</h2>
        <ul className={cn("flex flex-col gap-4")}>
          <li className={cn("flex items-center")}>
            <div className={cn("flex flex-col")}>
              <span className={cn("text-xl")}>Master Updater</span>
              <span className={cn("text-xs mt-2")}>
                updated in {jobs?.master_updater.last_executed_at ?? "-"}
              </span>
            </div>
            <div className={cn("w-8")} />
            <div className={cn("flex flex-col")}>
              {isExecutingMasterUpdate ? (
                <span>Running...</span>
              ) : (
                <Button variant={"secondary"} onClick={execMasterUpdater}>
                  Run
                </Button>
              )}
            </div>
          </li>
          <li className={cn("flex items-center")}>
            <div className={cn("flex flex-col")}>
              <span className={cn("text-xl")}>
                Refresh Financial Announcements
              </span>
              <span className={cn("text-xs mt-2")}>
                updated in{" "}
                {jobs?.refresh_financial_announcement.last_executed_at ?? "-"}
              </span>
            </div>
            <div className={cn("w-8")} />
            <div className={cn("flex flex-col")}>
              {isExecutingRefreshFinancialAnnouncement ? (
                <span>Running...</span>
              ) : (
                <Button
                  variant={"secondary"}
                  onClick={execRefreshFinancialAnnouncement}
                >
                  Run
                </Button>
              )}
            </div>
          </li>
        </ul>
      </main>
    </DashboardLayout>
  );
}

interface Job {
  master_updater: {
    job_id: string;
    last_executed_at: string;
  };
  refresh_financial_announcement: {
    job_id: string;
    last_executed_at: string;
  };
}
