const baseURL = process.env.REACT_APP_API_BASE_URL;

export function login(username: string, password: string) {
  return fetch(`${baseURL}/login`, {
    method: "POST",
    cache: "no-cache",
    credentials: "include",
    headers: {
      // "X-Forwarded-Host": 'api.dashboard.example.com',
    },
    body: JSON.stringify({
      username: username,
      password: password,
    }),
  });
}

export function logout() {
  return fetch(`${baseURL}/logout`, {
    method: "POST",
    cache: "no-cache",
    credentials: "include",
    headers: {
      // "X-Forwarded-Host": 'api.dashboard.example.com',
    },
  });
}

export function fetchSession() {
  return fetch(`${baseURL}/session`, {
    method: "GET",
    cache: "no-cache",
    credentials: "include",
    headers: {
      // "X-Forwarded-Host": 'api.dashboard.example.com',
    },
  });
}

export function fetchDividendScreening(hasHolder: boolean, date: string) {
  const params = {
    filter_has_holder: hasHolder ? "true" : "false",
    filter_dividend_date: date,
  };
  const urlSearchParam = new URLSearchParams(params).toString();
  return fetch(`${baseURL}/dividend-screening?${urlSearchParam}`, {
    method: "GET",
    cache: "no-cache",
    credentials: "include",
    headers: {
      // "X-Forwarded-Host": 'api.dashboard.example.com',
    },
  });
}

export function fetchPortfolio() {
  return fetch(`${baseURL}/portfolio`, {
    method: "GET",
    cache: "no-cache",
    credentials: "include",
    headers: {
      // "X-Forwarded-Host": 'api.dashboard.example.com',
    },
  });
}

export function fetchPortfolioDetail(id: number) {
  return fetch(`${baseURL}/portfolio/${id}`, {
    method: "GET",
    cache: "no-cache",
    credentials: "include",
    headers: {
      // "X-Forwarded-Host": 'api.dashboard.example.com',
    },
  });
}

export function fetchOperations() {
  return fetch(`${baseURL}/operations`, {
    method: "GET",
    cache: "no-cache",
    credentials: "include",
    headers: {
      // "X-Forwarded-Host": 'api.dashboard.example.com',
    },
  });
}

export function postMasterUpdaterOperation() {
  return fetch(`${baseURL}/operations/master-updater`, {
    method: "POST",
    cache: "no-cache",
    credentials: "include",
    headers: {
      // "X-Forwarded-Host": 'api.dashboard.example.com',
    },
  });
}

export function postRefreshFinancialAnnouncementOperation() {
  return fetch(`${baseURL}/operations/refresh-financial-announcement`, {
    method: "POST",
    cache: "no-cache",
    credentials: "include",
  });
}

export function fetchNews() {
  return fetch(`${baseURL}/news`, {
    method: "GET",
    cache: "no-cache",
    credentials: "include",
    headers: {
      // "X-Forwarded-Host": 'api.dashboard.example.com',
    },
  });
}

export function postNews(code: string) {
  return fetch(`${baseURL}/news`, {
    method: "POST",
    cache: "no-cache",
    credentials: "include",
    headers: {
      // "X-Forwarded-Host": 'api.dashboard.example.com',
    },
    body: JSON.stringify({
      stock_code: code,
    }),
  });
}

export function deleteNews(code: string) {
  return fetch(`${baseURL}/news/${encodeURI(code)}`, {
    method: "DELETE",
    cache: "no-cache",
    credentials: "include",
    headers: {
      // "X-Forwarded-Host": 'api.dashboard.example.com',
    },
  });
}

export function postPortfolio(
  code: string,
  volume: string,
  averagePrice: string,
) {
  return fetch(`${baseURL}/portfolio`, {
    method: "POST",
    cache: "no-cache",
    credentials: "include",
    headers: {
      // "X-Forwarded-Host": 'api.dashboard.example.com',
    },
    body: JSON.stringify({
      stock_code: code,
      volume: Number(volume),
      ave_unit_price: Number(averagePrice),
    }),
  });
}

export function editPortfolio(
  id: number,
  code: string,
  volume: string,
  averagePrice: string,
  noteStopLoss: string,
  noteTakeProfit: string,
  noteDividendPolicy: string,
) {
  return fetch(`${baseURL}/portfolio/${encodeURI(id.toString())}`, {
    method: "PATCH",
    cache: "no-cache",
    credentials: "include",
    headers: {
      // "X-Forwarded-Host": 'api.dashboard.example.com',
    },
    body: JSON.stringify({
      stock_code: code,
      volume: Number(volume),
      ave_unit_price: Number(averagePrice),
      note_stop_loss: Number(noteStopLoss),
      note_take_profit: Number(noteTakeProfit),
      note_dividend_policy: noteDividendPolicy,
    }),
  });
}

export function fetchSchedules() {
  return fetch(`${baseURL}/financial-announcements`, {
    method: "GET",
    cache: "no-cache",
    credentials: "include",
  });
}
