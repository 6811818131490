import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { SessionContext } from "../App";
import { Button } from "../components/ui/button";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "../components/ui/drawer";
import { Input } from "../components/ui/input";
import { fetchPortfolio, fetchSchedules, postPortfolio } from "../data/api";
import DashboardLayout from "../layout/dashboard";
import { cn } from "../lib/utils";

export default function SchedulePage() {
  const session = useContext(SessionContext);
  const [response, setResponse] = useState<Schedule | null>();

  const fetch = useCallback(async () => {
    const response = await fetchSchedules();
    if (response.status < 200 || response.status > 299) {
      return;
    }
    const data = JSON.parse(await response.text()) as PortfolioItems;
    setResponse(data);
  }, []);

  useEffect(() => {
    (async () => await fetch())();
  }, [fetch]);

  // Guard no-authenticated
  if (!session?.username) return <></>;

  return (
    <DashboardLayout>
      <main className={cn("w-full")}>
        <header className={cn("pb-6 flex justify-end w-full")} />
        <div className={cn("flex flex-col gap-6")}>
          {response?.items.map((item) => {
            return (
              <div
                key={item.stock.code}
                className={cn(
                  "px-4 py-3 flex flex-col border-2 rounded mb-2 gap-2",
                )}
              >
                <div className={cn("flex items-center")}>
                  <div className={cn("font-bold")}># {item.stock.code}</div>
                  <div className={cn("w-2")} />
                  <div className={cn("flex text-xs text-muted-foreground")}>
                    {item.stock.name}
                  </div>
                </div>
                <div
                  className={cn(
                    "flex flex-col lg:flex-row lg:justify-start lg:items-center gap-2 lg:gap-4",
                  )}
                >
                  <div className={cn("flex w-48 text-lg")}>
                    <div>{item.announcement?.scheduled_date}</div>
                    <div className={cn("w-2")} />
                    <div>{item.announcement?.announcement_kind}</div>
                  </div>
                  <div className={cn("flex w-80 text-sm")}>
                    <div>FY: {item.announcement?.fiscal_year_end}</div>
                  </div>
                  <div className={cn("flex flex-1 justify-end")}>
                    <a
                      className={cn("bg-secondary py-2 px-4 inline-block")}
                      href={`https://www.buffett-code.com/company/${item.stock.code}/`}
                    >
                      BC
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {response?.items.length === 0 ? (
          <tr>
            <td>empty.</td>
          </tr>
        ) : null}
      </main>
    </DashboardLayout>
  );
}
