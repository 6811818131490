import { ArrowLeftIcon } from "lucide-react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SessionContext } from "../App";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Textarea } from "../components/ui/textarea";
import { editPortfolio, fetchPortfolioDetail } from "../data/api";
import DashboardLayout from "../layout/dashboard";
import { cn } from "../lib/utils";

export default function PortfolioDetailPage() {
  interface Item {
    id: number;
    user_id?: number;
    stock_code: string;
    ave_unit_price: number;
    volume?: number;
    note_stop_loss?: number;
    note_take_profit?: number;
    note_account_closing_date?: number;
    note_dividend_policy: string;
    created_at: string;
    updated_at: string;
  }

  const { id } = useParams();
  const session = useContext(SessionContext);
  const navigate = useNavigate();
  const [item, setItem] = useState<Item | null>(null);
  const [volume, setVolume] = useState("");
  const [averagePrice, setAveragePrice] = useState("");
  const [stopLoss, setStopLoss] = useState("");
  const [takeProfit, setTakeProfit] = useState("");
  const [closingDate, setClosingDate] = useState("");
  const [dividendPolicy, setDividendPolicy] = useState("");

  const fetch = useCallback(async () => {
    const response = await fetchPortfolioDetail(Number(id));
    if (response.status < 200 || response.status > 299) {
      return;
    }
    const data = JSON.parse(await response.text()) as Item;
    setItem(data);
    setVolume(data.volume?.toString() ?? "");
    setAveragePrice(data.ave_unit_price?.toString() ?? "");
    setStopLoss(data.note_stop_loss?.toString() ?? "");
    setTakeProfit(data.note_take_profit?.toString() ?? "");
    setClosingDate(data.note_account_closing_date?.toString() ?? "");
    setDividendPolicy(data.note_dividend_policy);
  }, [id]);

  const doEdit = async () => {
    if (!item) return;
    const response = await editPortfolio(
      item.id,
      item.stock_code,
      volume,
      averagePrice,
      stopLoss,
      takeProfit,
      dividendPolicy,
    );
    if (response.status < 200 || response.status > 299) {
      return;
    }
    navigate("/portfolio");
  };

  useEffect(() => {
    (async () => await fetch())();
  }, [fetch]);

  // Guard no-authenticated
  if (!session?.username) return <></>;

  return (
    <DashboardLayout>
      <div className={cn("flex flex-col w-full")}>
        <header className={"flex gap-4"}>
          <ArrowLeftIcon onClick={() => navigate("/portfolio")} />
          <div>#{id}</div>
        </header>
        {item ? (
          <div className={cn("my-2 w-full mt-8")}>
            <div>
              <div>{item.stock_code}</div>
            </div>
            <div className={cn("h-4")} />
            <div>
              <div>ave_unit_price</div>
              <Input
                type="text"
                inputMode="decimal"
                placeholder="Average Price"
                value={averagePrice}
                className={cn("my-2")}
                onChange={(e) => setAveragePrice(e.target.value)}
                required
              />
            </div>
            <div className={cn("h-4")} />
            <div>
              <div>volume</div>
              <Input
                type="text"
                inputMode="decimal"
                placeholder="Volume"
                value={volume}
                className={cn("my-2")}
                onChange={(e) => setVolume(e.target.value)}
                required
              />
            </div>
            <div className={cn("h-4")} />
            <div>
              <div>note_stop_loss</div>
              <Input
                type="text"
                inputMode="decimal"
                placeholder="SL"
                value={stopLoss}
                className={cn("my-2")}
                onChange={(e) => setStopLoss(e.target.value)}
                required
              />
            </div>
            <div className={cn("h-4")} />
            <div>
              <div>note_take_profit</div>
              <Input
                type="text"
                inputMode="decimal"
                placeholder="TP"
                value={takeProfit}
                className={cn("my-2")}
                onChange={(e) => setTakeProfit(e.target.value)}
                required
              />
            </div>
            <div className={cn("h-4")} />
            <div>
              <div>note_account_closing_date</div>
              <Input
                type="text"
                placeholder="Closing Date"
                value={closingDate}
                className={cn("my-2")}
                onChange={(e) => setClosingDate(e.target.value)}
                required
              />
            </div>
            <div className={cn("h-4")} />
            <div>
              <div>note_dividend_policy</div>
              <Textarea
                placeholder="Dividend Policy"
                value={dividendPolicy}
                className={cn("my-2")}
                rows={8}
                onChange={(e) => setDividendPolicy(e.target.value)}
              />
            </div>
            <div className={cn("h-6")} />

            <Button onClick={doEdit}>Save</Button>
          </div>
        ) : null}
      </div>
    </DashboardLayout>
  );
}
